import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import arrowSrc from '../../assets/icon-arrow.svg';

const Container = styled.div`
  align-items: flex-start;

  ${({ desktop }) => desktop && css`
    display: none;

    @media(min-width: 960px) {
      display: flex;
    }
  `};

  ${({ mobile }) => mobile && css`
    display: flex;
    padding: 56px 32px;

    @media(min-width: 960px) {
      display: none;
    }
  `};
`;

const StyledImage = styled.img`
  margin: 7px 16px 0 0;
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 18px;
  margin: 0;

  > a {
    color: currentColor;
    white-space: nowrap;
  }
`;

const ContactBlurb = ({ desktop, mobile }) => (
  <Container desktop={desktop} mobile={mobile}>
    <StyledImage src={arrowSrc} alt="" />
    <Text>
      If you need additional information or support with enrollment, please contact us at:
      {' '}
      <a href="mailto:GMdigitalsignage@bluewatertech.com">GMdigitalsignage@bluewatertech.com</a>
      {' '}
      or
      {' '}
      <a href="tel:1-866-766-8454">1-866-766-8454</a>
      .
    </Text>
  </Container>
);

ContactBlurb.propTypes = {
  desktop: PropTypes.bool,
  mobile: PropTypes.bool,
};

ContactBlurb.defaultProps = {
  desktop: false,
  mobile: false,
};

export default ContactBlurb;
