import styled from 'styled-components';

const ContainerLeft = styled.section`
  background: ${({ theme }) => theme.colors.white};
  flex: 1 1 0%;
  padding: 32px;

  @media(min-width: 960px) {
    padding: 5%;
    width: 52%;
  }
`;

export default ContainerLeft;
