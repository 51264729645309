import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';

const slide = keyframes`
  0% {
    transform: scaleX(0.556);
  }
  100% {
    transform: scaleX(1);
  }
`;

const slideMobile = keyframes`
  0% {
    transform: scaleX(0.8);
  }
  100% {
    transform: scaleX(1);
  }
`;

const ContainerRight = styled.section`
  color: ${({ theme }) => theme.colors.white};
  flex: 1 1 0%;
  position: relative;

  ${({ desktop }) => desktop && css`
    display: none;
  `};

  &::before {
    background: linear-gradient(95.47deg, ${({ theme }) => theme.colors.primaryDark} 0%, ${({ theme }) => theme.colors.primary} 100%);
    content: '';
    height: 100%;
    position: absolute;
    transform-origin: right;
    transition: transform 500ms ease-in-out;
    width: 100%;

    ${({ fullWidth }) => fullWidth && css`
      animation: ${slide} 500ms ease-in-out forwards;
    `};

    ${({ fullWidth }) => !fullWidth && css`
      animation: ${slideMobile} 500ms ease-in-out reverse forwards;
      border-radius: 8px 0px 0px 8px;
    `};
  }

  @media(min-width: 960px) {
    width: 48%;

    ${({ desktop }) => desktop && css`
      display: block;
    `};

    &::before {
      border-radius: 0;

      ${({ fullWidth }) => !fullWidth && css`
        animation: ${slide} 500ms ease-in-out reverse forwards;
      `};
    }
  }
`;

ContainerRight.propTypes = {
  desktop: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

ContainerRight.defaultProps = {
  desktop: false,
  fullWidth: false,
};

export default ContainerRight;
