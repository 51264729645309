import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import theme from '../theme';
import GlobalStyles from './global-styles';
import ContactBlurb from './common/contact-blurb';
import Footer from './footer';

const Wrapper = styled.div`
  position: relative;
`;

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyles />
      <Wrapper>
        <main>
          {children}
        </main>
        <ContactBlurb mobile />
        <Footer />
      </Wrapper>
    </>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
