import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import ContainerLeft from '../components/common/container-left';
import MainContent from '../components/common/main-content';
import ContainerRight from '../components/common/container-right';
import CardList from '../components/card-list';
import logoSrc from '../assets/logo2x.png';

const Content = styled.div`
  @media(min-width: 960px) {
    display: flex;
    min-height: 100vh;
  }
`;

const StyledLink = styled(Link)`
  display: block;
  max-width: 201px;

  > img {
    display: block;
    height: auto;
    width: 100%;
  }
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Home" keywords={['gmdn', 'gmdn enroll']} />
    <Content>
      <ContainerLeft>
        <StyledLink to="/">
          <img src={logoSrc} alt="GMDN Enroll" />
        </StyledLink>
        <MainContent />
      </ContainerLeft>
      <ContainerRight>
        <CardList />
      </ContainerRight>
    </Content>
  </Layout>
);

export default IndexPage;
