import React from 'react';
import styled from 'styled-components';
import terms from '../assets/Terms_and_Conditions_of_GMDN_Service_Contract.pdf';

const Container = styled.footer`
  font-size: 14px;
  padding: 32px;

  @media(min-width: 960px) {
    padding: 32px 5%;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
`;

const Footer = () => (
  <Container>
    &copy;
    {' '}
    {(new Date().getFullYear())}
    {' '}
    Bluewater Technologies. All Rights Reserved &nbsp;
    <a href = {terms} >Terms and Conditions</a>

  </Container>

);

export default Footer;
