import React from 'react';
import styled, { keyframes } from 'styled-components';
import Card from './common/card';
import icon1Src from '../assets/icon-1.svg';
import icon2Src from '../assets/icon-2.svg';
import icon3Src from '../assets/icon-3.svg';
import icon4Src from '../assets/icon-4.svg';
import guideSrc from '../assets/GMDN-Guide.pdf';
import pricingSrc from '../assets/GMDN-Pricing.pdf';
import gmdtvSrc from '../assets/GMDN-GMDTV.pdf';

const fadeInSlide = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-16px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const List = styled.ul`
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: 32px;
  list-style-type: none;
  margin: 0;
  padding: 32px;
  position: relative;

  @media(min-width: 960px) {
    padding: 11% 80px 32px 0;
  }
`;

const ListItem = styled.li`
  animation: ${fadeInSlide} 500ms ease-in-out 500ms forwards;
  opacity: 0;
  padding: 0;
  transform: translateY(-16px);

  &:nth-of-type(2) {
    animation-delay: calc(500ms + 0.2s);
  }
  &:nth-of-type(3) {
    animation-delay: calc(500ms + 0.3s);
  }
  &:nth-of-type(4) {
    animation-delay: calc(500ms + 0.4s);
  }

  &:last-of-type {
    margin: 0;
  }
`;

const CardList = () => (
  <List>
    <ListItem>
      <Card
        icon={icon1Src}
        title="Learn More"
        copy="About the GM internal solution offered exclusively through Bluewater. It’s already installed in over 1,200 dealerships!"
        linkTo={guideSrc}
        external
      />
    </ListItem>
    <ListItem>
      <Card
        icon={icon4Src}
        title="GMDTV"
        copy="Eliminate competitor ads from your Guest Lounge TVs with General Motors Dealership Television."
        linkTo={gmdtvSrc}
        external
      />
    </ListItem>
    <ListItem>
      <Card
        icon={icon3Src}
        title="Enroll in a GM Digital Signage Program(IDDN/Pinnacle)"
        copy="Receive a free consultation and digital signage quote."
        linkTo="/enroll"
      />
    </ListItem>
  </List>
);

export default CardList;
