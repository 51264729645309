import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LinkCheck from './link-check';

const ButtonLink = styled(LinkCheck)`
  background: linear-gradient(0deg, rgba(13, 93, 187, 0.04), rgba(13, 93, 187, 0.04)), ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grayLight};
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
  display: flex;
  height: 100%;
  outline: none;
  padding: 20px 32px;
  text-align: left;
  text-decoration: none;
  transition: all 250ms ease-in-out;
  width: 100%;
  -webkit-tap-highlight-color: transparent;

  &:hover, &:focus {
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 4px 24px rgba(13, 93, 187, 0.16);
    transform: translateX(-16px);
  }
`;

const Content = styled.div`
  width: 100%;

  @media(min-width: 960px) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`;

const Icon = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.colors.primary};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-shrink: 0;
  height: 64px;
  justify-content: center;
  margin: 0 0 24px 0;
  width: 64px;

  > img {
    display: block;
    width: 100%;
    max-width: 20px;
  }

  @media(min-width: 960px) {
    margin: 0 0 0 24px;
    order: 2;
  }
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 8px 0;
`;

const Text = styled.p`
  font-size: 17px;
  margin: 0;
`;

const Card = ({
  icon, title, copy, linkTo, external,
}) => (
  <ButtonLink to={linkTo} external={external}>
    <Content>
      <Icon>
        <img src={icon} alt={title} />
      </Icon>
      <div>
        <Title>
          {title}
        </Title>
        <Text>
          {copy}
        </Text>
      </div>
    </Content>
  </ButtonLink>
);

Card.propTypes = {
  external: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  copy: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
};

Card.defaultProps = {
  external: false,
  linkTo: '',
};

export default Card;
