const theme = {
  colors: {
    // globals
    gray: 'rgba(64, 69, 64, 0.5)',
    grayLight: '#E3E3E3',
    grayDark: 'rgba(75, 75, 75, 0.64)',
    warning: '#F1C014',
    // branding
    black: '#404540',
    white: '#FFFFFF',
    primary: '#0D5DBB',
    primaryLight: 'rgba(13, 93, 187, 0.16)',
    primaryDark: '#082A52',
  },
};

export default theme;
