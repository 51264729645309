import React from 'react';
import styled from 'styled-components';
import ContactBlurb from './contact-blurb';

const Text = styled.p`
  margin: 56px 0;

  @media(min-width: 960px) {
    margin-top: 90px;
  }
`;

const MainContent = () => (
  <>
    <Text>
      <strong>Welcome to General Motors Digital Network (GMDN). </strong>
      The GMDN Dealer Digital Signage Solution is the exclusive solution
      designed by the internal GM Telecom and End-User Services Infrastructure team.
      Delivered in partnership with Bluewater, the solution is designed as an in-store marketing
      solution that empowers the dealership to bring on-demand buying experience to customers.
      Please select an option.
    </Text>
    <ContactBlurb desktop />
  </>
);

export default MainContent;
