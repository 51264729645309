import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

// Since DOM elements <a> cannot receive activeClassName,
// destructure the prop here and pass it only to GatsbyLink
const LinkCheck = ({
  children, to, activeClassName, external, ...rest
}) => {
  // Use <a> for external links, and GatsbyLink for others
  if (external) {
    return (
      <a href={to} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink to={to} activeClassName={activeClassName} {...rest}>
      {children}
    </GatsbyLink>
  );
};

LinkCheck.propTypes = {
  children: PropTypes.node.isRequired,
  external: PropTypes.bool,
  to: PropTypes.string.isRequired,
  activeClassName: PropTypes.string,
};

LinkCheck.defaultProps = {
  activeClassName: null,
  external: false,
};

export default LinkCheck;
